angular.module('enervexSalesappApp').controller('DocumentCtrl', function($scope, $sce,  $stateParams, $q, ProposalTables, DocumentType, $state, User, Account, Job, JobService, QuotesService, DocumentsService, Quote, FileUploader, Auth, Flash, Util, $uibModal, _, $window, $timeout, VentMaterial, Fan, Property, Fitting, LineitemSource, ChartUtil) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.documentId = $stateParams.documentId;
	$scope.checkedMembers = [];
	$scope.setChecks = false;
	$scope.checkedEmailMembers = [];
	$scope.shouldPrint = $stateParams.print;
	$scope.ctx = {
		formChanged: false,
		overall: true,
		designs: true,
		quote: true,
		showShippingRow: false,
		pdfRender: true,
		useTabs: false,
		showAllQuotes: false,
		regenerateOnChange: false,
		iframeState: "loading"
	};

	var getTarget = function() {
		var target = null
		if ($scope.editForm) {
			if ($scope.ctx.useTabs) {
				target = "edittabs"
			} else {
				target = "edit"
			}
		} else {
			target = "read"
		}
		return target
	}
	var messageHandler = function(event) {
		var data = event.data
		var target = getTarget()
		if (!data) {
			// console.log("doc parent("+target+") ignoring empty event from "+event.origin)
		} else {
			var token = data.token
			var target = data.target
			if (!(target == getTarget() && token === _token)) {
				console.log("doc parent("+target+") ignoring event mismatch target and token")
			} else if (data.type == "app.registered") {
				console.log("doc parent("+target+") "+data.type+" from "+event.origin, data)
			} else if (data.type == "app.loading") {
				console.log("doc parent("+target+") "+data.type+" from "+event.origin, data)
			} else if (data.type == "app.rendered") {
				var diff = Date.now() - _loadStart
				$scope.loadTime = moment.duration(diff).asSeconds() + " seconds"
				$scope.ctx.iframeState = "loaded"
				console.log("doc parent("+target+") "+data.type+" from "+event.origin, data)
				try {
					$scope.$apply()
				}catch(err){
					console.log("doc error applying iframestate", err)
				}
			} else {
				console.log("doc parent("+target+") ignoring event w type "+data.type + " from "+event.origin, data)
			}
		}
	}

	$scope.$on("$destroy", function() {
		console.log("doc parent destroy removing listener")
		window.removeEventListener('message', messageHandler)
		Flash.clear()
	})

	window.addEventListener('message', messageHandler);

	$scope.iframeUrl = function(target){
		return $sce.trustAsResourceUrl( window.enervexConfig.iframeUrl + "?target="+target);
	};
	$scope.configs = Property.configs();
	$scope.getUnits = Util.getUnits;
	$scope.downLength = Util.downLength;
	$scope.me = Auth.getCurrentUser;

	var uploader = $scope.uploader = new FileUploader({});
	$scope.ventMaterials = VentMaterial.query()
	$scope.faultItemName = Util.faultItemName
	$scope.ventMaterialName = function(code){
		var existing = _.find($scope.ventMaterials, function(v){
			return v.code == code
		})
		if (existing){
			return existing.name
		} else {
			return code
		}
	}
	$scope.ventMaterialShortName = function(code){
		var existing = _.find($scope.ventMaterials, function(v){
			return v.code == code
		})
		if (existing){
			return existing.shortName
		} else {
			return code
		}
	}
	$scope.changed = function() {
		$scope.ctx.formChanged = true
		var designs = _.get($scope.document,"quote.quote.designs")
		$scope.ctx.showShippingRow = Util.showShippingRow(designs)
		if ($scope.regenerateOnChange) {
			$scope.setIframeSource()
		}
	}
	if ($scope.regenerateOnChange) {
		$scope.setIframeSource = _.debounce(_setIframeSource, 2000)
	} else {
		$scope.setIframeSource = _setIframeSource
	}
	var _token = 0
	var _loadStart = null
	function _setIframeSource() {
		if (!$scope.ctx.compatible) {
			console.log("not compatible")
			return
		} else {
			console.log("now compatible")
		}
		// console.log("doc _setIframeSource called")
		var iframe = document.getElementById("pdf-report-frame");
		if (iframe) {
			if ($scope.document && $scope.lineitemSources && $scope.configs) {
				$scope.ctx.iframeState = "loading"
				var proposal = Util.toJsonObject($scope.document)
				Util.computeGrandTotal(proposal.quote.quote, $scope.configs.quote_extras_config)

				if ($scope.configs.omit_standardtable == 'true' && proposal) {
					proposal = _.clone(proposal)
					proposal.inclusions = _.clone(proposal.inclusions)
					proposal.inclusions.standardTable = false
				}
				var lineitemSources = Util.toJsonObject($scope.lineitemSources)
				var properties = Util.toJsonObject($scope.configs)
				var ventMaterials = Util.toJsonObject($scope.ventMaterials)
				var fans = Util.toJsonObject($scope.fans)
				var target = getTarget()
				_token = _token + 1
				$scope.loadTime = null
				_loadStart = Date.now()
				
				var dataToSend = {
					proposal: proposal,
					lineitemSources: lineitemSources,
					properties: properties,
					ventMaterials: ventMaterials,
					target: target,
					fans: fans,
					target: target,
					token: _token,
					proposalTableSettings: $scope.proposalTableSettings,
					isNew: $scope.documentId == 'new' ? true : false
				};

				var payload = {
					type: 'CUSTOM_EVENT',
					data: dataToSend
				}			    // console.log('Sending data to React app:', payload);
				iframe.contentWindow.postMessage(payload, window.enervexConfig.iframeUrl);
			} else {
				console.log("doc _setIframeSource not all data ready, not sending")
			}
		}
	}
	function checkCompatiable() {
		console.log("checkCompatiable() entry")
		Flash.clear()
		var designs = _.get($scope.document, "quote.quote.designs")
		var incompatiableDesigns = _.filter(designs, function(designObj){
			var versionInfo = designObj.design.versionInfo
			if (!versionInfo) {
				return true
			} else if (designObj.design.application.computeAs == "DRY" || designObj.design.application.computeAs == "BWH" || designObj.design.application.computeAs == "COM" ) {
				return !(versionInfo.bom >= Util.frameworkVersion && versionInfo.calc >= Util.frameworkVersion)
			} else if (designObj.design.application.computeAs == "none") {
				return !(versionInfo.saveDesign >= Util.frameworkVersion)
			} else {
				return false
			}
		})
		if (incompatiableDesigns.length > 0) {
			var message = "<strong>Error!</strong> Incompatable designs referenced in this quote, please re-compute or re-save them"
			_.each(incompatiableDesigns, function(designObj, index){
				var location = (designObj.design.application.computeAs == "none") ? "?mode=edit&dirty=true" : "/drawing"
				message = message + '<div><a href="/accounts/'+ $scope.accountId + '/jobs/'+ $scope.jobId + '/designs/'+designObj.design._id + location + '">' + designObj.design.name + " v"+designObj.version +'</a></div>'
			})
			Flash.create('danger', message, 0, {}, true);
		}
		$scope.ctx.compatible = incompatiableDesigns.length == 0
		console.log("checkCompatiable() set compatible=" + $scope.ctx.compatible + " based on incompatiableDesigns.length=" + incompatiableDesigns.length)
		_setIframeSource()
	}
	$scope.canRender = function() {
		return $scope.ctx.compatible && $scope.ctx.pdfRender
	}
	$scope.quoteRemoved = function(item1, design) {
		var foundItems = _.map($scope.document.quote.quote.groups,function(group){
			return _.find(group.items,function(item2){
				if (item2.design && item2.design._id == design._id) {
					if (item2.designLineItemId == item1._id && item2.designLineItemId) {
						return true
					} else if (item1.product && item2.product){
						if (item2.product._id != item1.product._id) {
							return false
						}  else {
							if (item1.origin == item2.origin) {
								return true
							}
						}
					} else if (item2.product || item1.product){
						return false
					} else if (item1.customProduct && item2.customProduct){
						var compare = item1.customProduct.name == item2.customProduct.name;
						if (compare) {
							return true
						}
					} else if (item2.customProduct || item1.customProduct){
						return false
					} else {
						if (item1.notes == item2.notes) {
							return true
						}
					}
				}
			})
		})
		var item = _.first(_.compact(foundItems))
		var name1 = item1.product && item1.product.name,
			name2 = item && item.product && item.product.name,
			notes1 = item1.notes, 
			notes2 = item && item.notes;

		if (item && item.quoteRemoved) {
			return true
		} else if (item && item.removed) {
			return true
		} else {
			return false
		}
	}
	Fan.query({limit:3000}).$promise.then(function(fans){
		$scope.fans = fans;
		$scope.fanName = function(code) {
			var fan = _.find($scope.fans, function(fan){
				var fanCode = (fan.Quantity ==1) ? fan.code : fan.code+"-"+fan.Quantity;
				return fanCode == code;
			})
			return (fan) ? fan.name : code
		}
	})
	Fitting.query({}).$promise.then(function(fittings){
		$scope.fittings = fittings
	})
	ProposalTables.query({}).$promise.then(function(tables) {
		$scope.proposalTableSettings = tables
	})
	$scope.fittingName = function(code){
		var existing = _.find($scope.fittings, function(v){
			return v.code == code
		})
		if (existing){
			return existing.name
		} else {
			return code
		}
	}
	$scope.fittingShortname = function(code){
		var existing = _.find($scope.fittings, function(v){
			return v.code == code
		})
		if (existing){
			return existing.shortname
		} else {
			return code
		}
	}
	$scope.ignoreSubmit = function($event){
		$event.preventDefault()
	}
	$scope.deliberatelyTrustDangerousSnippet = function() {
		return $sce.trustAsHtml($scope.document.content);
	};
	function colorForIndex(index, option2) {
		var fanColors = ["#2fb3b2", "#76382a", "#c72802", "#ef9d58", "#118f0b", "#b6a6e6", "#d27223", "#547baa", "#24efcc", "#12dca7", "#d06ca1", "#dc5903", "#57d644", "#4c4e1", "#c5571b", "#757797", "#21cde5", "#fb2417", "#ecdc40", "#d95297", "#74c7cc", "#4bd7ec", "#8e6de2", "#5b154b", "#9c3457", "#bed90a", "#522115", "#7edf53", "#fd5c03", "#3b9946"];
		if (option2) {
			fanColors.reverse()
		}
		return fanColors[index];
	}
	function setup() {
		$scope.confirmDelete = false;
		if ($stateParams.mode == "edit") {
			$scope.mode = "edit"
		}
		if ($scope.mode === 'edit'){
			$scope.editForm = true;
		}
		Account.get({
			id: $stateParams.accountId
		}).$promise.then(function(res){
			$scope.account = res;
		});
		LineitemSource.query({}).$promise.then(function(res){
			$scope.lineitemSources = res
		})
		Job.get({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		}).$promise.then(function(job){
			$scope.job = job;
			$scope.isInternalMember = Auth.isInternalMember(job);
			$scope.permissions = Auth.jobPermissions($scope.job, $scope.configs)
			$scope.newComment = {
				notifyMembers:[]
			};
			Util.setNotifyMembersType($scope.newComment, $scope.job)
			if ($stateParams.documentId == 'new'){
				$scope.editForm = true;
				$scope.document = {
					job: job,
					inclusions: {
						locationData:true,
						pageBreaks: true,
						pageHeaders: true,
						terms:true,
						coverPage: true,
						summary: true,
						standardTable: true,

						designs:true,
						exhaustFanChart: true,
						supplyFanChart: true,
						calculationResults: true,
						designItems: true,
						designDrawing:true,
						fullSizeImage: true,
						greyscaleImage: true,
						showGrav: true,
						showMech: true,
						makeupAir:true,
						codeViolations: true,
						plume: true,
						heatrecovery: true,
						energySavings: $scope.configs.display_energySavings ? true : false,
						
						lineItems: true,
						lineItemCost: true,
						groupedCost: true,
						totalCost: true
					},
					quote: {
					},
					notifyMembers:[],
					visibility: Util.defaultVisibility($scope.job)
				}
				$scope.target = $scope.document
				$scope.setIframeSource()
				Util.setNotifyMembersType($scope.document, $scope.job)
				getQuotes();
			} else {
				getDoc();
				getDocumentVersions();
				fetchComments();
			}
		});
		DocumentType.query({}).$promise.then(function(docTypes){
			$scope.docTypes = docTypes
		})
	}
	setup()
	$scope.previousVersion = function(version){
		var latestV = _.max($scope.documentVersions, function(document){
			return document.version;
		});

		if (version != latestV) {
			// $stateParams.version = version.version;
			// $scope.mode = 'version';
			$state.go('proposal', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				documentId: $scope.documentId,
				version: version.version,
				mode: 'version'
			}, {
				reload: true,
				inherit: false
			});
			// $scope.document = version;
		} else {
			$state.go('proposal', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				documentId: $scope.documentId,
			}, {
				reload: true,
				inherit: false
			});
			// $scope.mode = '';
			// $scope.document = version;
		}
	}
	$scope.setInclusions = function(mode){
		$scope.ctx.formChanged = true;
		if (mode == 'all'){
			$scope.document.inclusions.locationData = true;
			$scope.document.inclusions.pageBreaks = true;
			$scope.document.inclusions.pageHeaders = true;
			$scope.document.inclusions.coverPage = true;
			$scope.document.inclusions.terms = true;
			$scope.document.inclusions.designs = true;
			$scope.document.inclusions.exhaustFanChart = true;
			$scope.document.inclusions.supplyFanChart = true;
			$scope.document.inclusions.calculationResults = true;
			$scope.document.inclusions.designItems = true;
			$scope.document.inclusions.designDrawing = true;
			$scope.document.inclusions.showGrav = true;
			$scope.document.inclusions.showMech = true;
			$scope.document.inclusions.makeupAir = true;
			$scope.document.inclusions.codeViolations = false;
			$scope.document.inclusions.lineItems = true;
			$scope.document.inclusions.lineItemCost = true;
			$scope.document.inclusions.groupedCost = true;
			$scope.document.inclusions.totalCost = true;
			$scope.document.inclusions.standardTable = true;
			$scope.document.inclusions.energySavings = true;
			$scope.document.inclusions.fullSizeImage = true;
			$scope.document.inclusions.greyscaleImage = true;
		} else if (mode == 'none'){
			$scope.document.inclusions.locationData = false;
			$scope.document.inclusions.pageBreaks = false;
			$scope.document.inclusions.pageHeaders = false;
			$scope.document.inclusions.coverPage = false;
			$scope.document.inclusions.terms = false;
			$scope.document.inclusions.designs = false;
			$scope.document.inclusions.exhaustFanChart = false;
			$scope.document.inclusions.supplyFanChart = false;
			$scope.document.inclusions.calculationResults = false;
			$scope.document.inclusions.designItems = false;
			$scope.document.inclusions.designDrawing = false;
			$scope.document.inclusions.showGrav = false;
			$scope.document.inclusions.showMech = false;
			$scope.document.inclusions.makeupAir = false;
			$scope.document.inclusions.codeViolations = false;
			$scope.document.inclusions.lineItems = false;
			$scope.document.inclusions.lineItemCost = false;
			$scope.document.inclusions.groupedCost = false;
			$scope.document.inclusions.totalCost = false;
			$scope.document.inclusions.standardTable = false;
			$scope.document.inclusions.energySavings = false;
			$scope.document.inclusions.fullSizeImage = false;
			$scope.document.inclusions.greyscaleImage = true;
		} else if (mode == 'quote'){
			$scope.document.inclusions.locationData = true;
			$scope.document.inclusions.pageBreaks = true;
			$scope.document.inclusions.pageHeaders = true;
			$scope.document.inclusions.coverPage = true;
			$scope.document.inclusions.terms = true;
			$scope.document.inclusions.designs = false;
			$scope.document.inclusions.exhaustFanChart = false;
			$scope.document.inclusions.supplyFanChart = false;
			$scope.document.inclusions.calculationResults = false;
			$scope.document.inclusions.designItems = false;
			$scope.document.inclusions.designDrawing = false;
			$scope.document.inclusions.showGrav = false;
			$scope.document.inclusions.showMech = false;
			$scope.document.inclusions.makeupAir = false;
			$scope.document.inclusions.codeViolations = false;
			$scope.document.inclusions.lineItems = true;
			$scope.document.inclusions.lineItemCost = true;
			$scope.document.inclusions.groupedCost = true;
			$scope.document.inclusions.totalCost = true;
			$scope.document.inclusions.standardTable = true;
			$scope.document.inclusions.energySavings = false;
			$scope.document.inclusions.fullSizeImage = false;
			$scope.document.inclusions.greyscaleImage = true;
		}
	}
	function clearFlash() {
		Flash.clear()
	}
	function addFlashError(obj){
		var message = null
		if (_.isString(obj)){
			message = obj
		} else if (obj.message) {
			message = obj.message
		} else if (obj.status) {
			message = obj.status + " " + obj.statusText;
		} else {
			message = "Error occurred " + obj
		}
		
		Flash.create('warning', message, 0, {}, true);
	}
	function addFlashInfo(message){
		
		$scope.success = true;
		Flash.create('success', message, 0, {}, true);
	}
	function quoteExists() {
		var isFound = _.find($scope.quotes, function(quote){
			return  $scope.document.quote.quote && $scope.document.quote.quote._id == quote._id && $scope.document.quote.version == quote.version
		})
		return (isFound) ? true : false
	}
	function getQuotes() {
		if (!$scope.permissions.edit){
			_selectQuote($scope.document.quote.quote)
			return
		}
		Quote.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}).$promise.then(function(quotes){
			quotes = _.sortBy(quotes, function(quote){
				return quote.name && quote.name.toLowerCase()
			})
			$scope.quotes = _.map(quotes, function(quote){
				quote.versionList = quote.version;
				return quote
			});
			if ($stateParams.quoteId){
				selectQuoteFromQueryParams()
			} else {
				_selectQuote($scope.document.quote.quote)
				if ($stateParams.documentId != "new" && !quoteExists()){
					addFlashError("Could not find selected quote, please select again")
				}
			}

		});
	}
	function getIdAttribute(object, attribute){
		if (object){
			return getId(object[attribute])
		}
	}
	function getId(object){
		if (object && object._id){
			return object._id
		} else if (_.isString(object)){
			return object;
		}
	}
	function getDocumentVersions(){
		DocumentsService.fetchDocumentVersions().$promise.then(function(res){
			$scope.documentVersions = res;
		})
	}
	$scope.editState = function(){
		$scope.editForm = true;
		$scope.mode = 'edit';
		$state.go('proposal', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			documentId: $scope.documentId,
			mode: 'edit'
		}, {
			inherit: false,
			reload: true
		});
	}
	function getDoc(){
		clearFlash()
		DocumentsService.getDocument().$promise.then(function(doc){
			$scope.document = doc;
			if ($scope.configs.display_energySavings == 'false') {
				$scope.document.inclusions.energySavings = false
			}
			$scope.target = $scope.document
			$scope.setIframeSource()
			$scope.ctx.showShippingRow = Util.showShippingRow($scope.document.quote.quote.designs)
			$scope.checkedMembers = $scope.document.explicitMembers;
			Util.setNotifyMembersType($scope.document, $scope.job);
			getQuotes();
			$scope.print();
		});
	}
	$scope.print = function() {
		if ($scope.shouldPrint && !$scope.ctx.pdfRender){
			$scope.shouldPrint = false;
			$timeout(function() {
				$window.print()
			}, 2000)
		}
	}
	function getExhFans(){
		if (!$scope.document.quote.quote.designs){
			return
		}
		_.each($scope.document.quote.quote.designs, function(design){
			_getExhFans(design);
		})
	}
	function _getExhFans(design){
		if (!(design.design.calcResult && design.design.calcResult.ExhFans && design.design.calcResult.ExhFans.length != 0)) {
			return
		}

		design.n3data = [];
		design.fanResults = [];
		var xArray = [];
		var yArray = [];

		_.each(design.design.calcResult.ExhFans, function(fan){
			var option = Util.getFanOption("exhaust", fan, design.design)
			if (!option || option.hidden == false){
				design.fanResults.push(fan);
			}
		})

		_.each(design.fanResults, function(fan, index){
			var yVal = design.fanResults.indexOf(fan) + 1;
			var yExpression = "value" + yVal;
			var sysCurveExpression = "sysCurve"

			if (fan.hidden) return;
			ChartUtil.setPoints(fan, fan.Points, design.n3data, xArray, yArray, yExpression)
			if ($scope.configs.calculate_fei == 'true') { 
				ChartUtil.setPoints(fan, fan.systemCurveData, design.n3data, xArray, yArray, sysCurveExpression)
			}
		})

		design.n3data.push({
			x: Number(design.design.calcResult.ExhVol),
			answer: Number(design.design.calcResult.ExhPres)
		});

		var largest = Math.ceil(Math.max.apply(Math, xArray) / 100) * 100;
		var chartMax = Math.max.apply(Math, yArray);

		design.n3options = ChartUtil.getN3Options(largest, chartMax, $scope.configs.calculate_fei)

		_.each(design.fanResults, function(fan, index){
			var yVal = design.fanResults.indexOf(fan) + 1;
			var yExpression = "value" + yVal;

			fan.color = colorForIndex(index);

			design.n3options.series.push({
				y: yExpression,
				color: colorForIndex(index),
				thickness: '2px',
				type: 'line',
				visible: true,
				drawDots: false
			})
		})

		

		design.n3PrintOptions = ChartUtil.getN3PrintOptions(largest, design.n3options.series)
	}
	function getSupFans(){
		if (!$scope.document.quote.quote.designs){
			return
		}
		_.each($scope.document.quote.quote.designs, function(design){
			_getSupFans(design)
		})
	}
	function _getSupFans(design){
		if (!(design.design.calcResult && design.design.calcResult.SupFans && design.design.calcResult.SupFans.length != 0)) {
			return
		}

		design.n4data = [];
		design.fanResults2 = [];
		var xArray = [];
		var yArray = [];

		if (!design.design.calcResult){
			return;
		}
		_.each(design.design.calcResult.SupFans, function(fan){
			var option = Util.getFanOption("supply", fan, design.design)
			if (!option || option.hidden == false){
				design.fanResults2.push(fan);
			}
		})

		_.each(design.fanResults2, function(fan){
			var yVal = design.fanResults2.indexOf(fan) + 1;
			var yExpression = "value" + yVal;
			var sysCurveExpression = "sysCurve"

			ChartUtil.setPoints(fan, fan.Points, design.n4data, xArray, yArray, yExpression)
			if ($scope.configs.calculate_fei == 'true') { 
				ChartUtil.setPoints(fan, fan.systemCurveData, design.n4data, xArray, yArray, sysCurveExpression)
			}
		})

		var largest = Math.ceil(Math.max.apply(Math, xArray) / 100) * 100;
		var chartMax = Math.max.apply(Math, yArray);

		design.n4data.push({
			x: Number(design.design.calcResult.SupVol),
			answer: Number(design.design.calcResult.SupPres)
		});

		design.n4options = ChartUtil.getN3Options(largest, chartMax, $scope.configs.calculate_fei)

		_.each(design.fanResults2, function(fan, index){
			var yVal = design.fanResults2.indexOf(fan) + 1;
			var yExpression = "value" + yVal;

			fan.color = colorForIndex(index, true);

			design.n4options.series.push({
				y: yExpression,
				color: colorForIndex(index, true),
				thickness: '2px',
				type: 'line',
				visible: true,
				drawDots: false
			})
		})

		

		design.n4PrintOptions = ChartUtil.getN3PrintOptions(largest, design.n4options.series)
	}
	$scope.toggleInclusion = function(inclusion){
		$scope.document.inclusions[inclusion] = !$scope.document.inclusions[inclusion]
		$scope.changed()
	}
	$scope.changeDocType = function(doc) {
		$scope.ctx.formChanged = true;
	}
	$scope.saveDocument = function(print){
		clearFlash()
		if (!quoteExists()){
			addFlashError("You must select a quote")
			return
		}
		if ($scope.document.visibility === 'explicit'){
			$scope.document.explicitMembers = $scope.checkedMembers;
		} else {
			$scope.document.explicitMembers = [];
		}
		if ($scope.document.notifyMembersType === 'all'){
			$scope.document.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if ($scope.document.notifyMembersType === 'some') {
			$scope.document.notifyMembers = $scope.checkedEmailMembers;
		} else if ($scope.document.notifyMembersType === 'none'){
			$scope.document.notifyMembers = [];
		}

		var payload = _.clone($scope.document)
		if (payload.job && payload.job._id) {
			payload.job = payload.job._id
		}
		if ($stateParams.documentId == "new"){
			DocumentsService.saveNewDocument($scope.document).$promise.then(function(res){
				$state.go("proposal", {
					accountId: $scope.accountId,
					jobId: $scope.jobId,
					documentId: res._id,
					print: print,
				}, {
					reload: true,
					inherit: false
				})
			}).catch(function(err){
				addFlashError(err)
			})
		} else {
			DocumentsService.saveDocument($scope.document).$promise.then(function(res){
				$state.go("proposal", {
					accountId: $scope.accountId,
					jobId: $scope.jobId,
					documentId: res._id,
					print: print,
				}, {
					reload: true,
					inherit: false
				})
			}).catch(function(err){
				addFlashError(err)
			})
		}
	}
	$scope.cancelDocument = function(){
		$scope.ctx.formChanged = false;
		if ($stateParams.documentId == "new"){
			$state.go('proposals', {
				accountId: $scope.accountId,
				jobId: $scope.jobId
			}, {
				reload: true,
				inherit: false
			});
		} else {
			$state.go('proposal', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				documentId: $scope.documentId,
			}, {
				reload: true,
				inherit: false
			});
			// getDoc();
		}
	}
	$scope.deleteDocument = function(doc){
		var parentElem = angular.element(document.querySelector('#document-main'));
		$uibModal.open({
			templateUrl: 'app/main/shared/confirm.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'ConfirmCtrl',
			size: 'sm',
			resolve: {
				title: function() {
					return "Delete Proposal"
				},
				body: function() {
					return "Are you sure you want to delete the proposal?"
				}
			}
		}).result.then(function(confirmed) {
			if (confirmed){
				DocumentsService.removeDocument(doc).$promise.then(function(){
					$state.go("proposals", {
						accountId: $scope.accountId,
						jobId: $scope.jobId,
					}, {
						reload: true,
						inherit: false
					})
				})
			} else {
				$scope.confirmDelete = false;
			}
		}).catch(function(){
			$scope.confirmDelete = false;
		});
	}
	$scope.toggleCheck = Util.toggleCheck;
	$scope.memberOf = Util.memberOf;
	function selectQuoteFromQueryParams() {
		if ($stateParams.quoteId && $scope.mode == "edit"){
			var existing = _.find($scope.quotes, function(q) {
				return q._id == $stateParams.quoteId
			})
			if (existing){
				existing.selected = true;
				existing.version = existing.versionList
				$scope.selectQuote(existing)
				if ($stateParams.documentId == "new"){
					$scope.document.name = existing.name.replace("quote", "proposal")
				}
			}
		}
	}
	function _selectQuote(selectedQuote) {
		if (!selectedQuote){
			$scope.quote = null;
			$scope.document.quote.quote = null
			return
		}
		$scope.quote = selectedQuote;
		_.each($scope.quotes, function(quote){
			if (quote._id != selectedQuote._id){
				quote.selected = false
			} else {
				quote.selected = true
				quote.version = selectedQuote.version
			}
		})
		if (!$scope.document.quote) {
			$scope.document.quote = {}
		}
		$scope.document.quote.quote = selectedQuote
		$scope.document.quote.version = selectedQuote.version
		$scope.document.quote.versionList = selectedQuote.versionList
		$scope.document.quote.selected = true
		if ($scope.permissions.edit){
			Quote.version({
				jobId: $stateParams.jobId,
				accountId: $stateParams.accountId,
				id: selectedQuote._id,
				version: selectedQuote.version
			}).$promise.then(function(quote){
				$scope.document.quote = {
					quote: quote,
					version: quote.version
				};
				getExhFans()
				getSupFans()
				$scope.ctx.showShippingRow = Util.showShippingRow($scope.document.quote.quote.designs)
				checkCompatiable()
			})
		} else {
			getExhFans()
			getSupFans()
			$scope.ctx.showShippingRow = Util.showShippingRow($scope.document.quote.quote.designs)
			checkCompatiable()
		}
	}
	$scope.selectQuote = function(selectedQuote){
		_selectQuote(selectedQuote);
		$scope.changed();
	}
	$scope.selectVersion = function(quote){
		if (quote.selected){
			_selectQuote(quote)
			$scope.changed();
		}
	}
	$scope.changeUserPermission = function(member, permission){
		member.permission = permission;
	}
	$scope.toggleEmailCheck = function(member){
		$scope.ctx.formChanged = true;
		var memberId = member.user._id
		if ($scope.checkedEmailMembers.indexOf(memberId) === -1){
			$scope.checkedEmailMembers.push(member.user._id);
		} else {
			$scope.checkedEmailMembers.splice($scope.checkedEmailMembers.indexOf(memberId), 1);
		}
	}
	$scope.selectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		_.each($scope.job.members, function(member){
			$scope.checkedEmailMembers.push(member.user._id);
		})
		$scope.setChecks = true;
	}
	$scope.deselectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
	}
	function fetchComments(){
		DocumentsService.getComments().$promise.then(function(documentComments){
			$scope.documentComments = documentComments;
		});
	}
	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembersType === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembersType === 'some' ) {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembersType === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue, function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.document.visibility);
				promises.push(DocumentsService.createAttachment(fd).$promise)
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				DocumentsService.createComment(newComment).$promise.then(function(res) {
					$scope.newComment = {
						notifyMembers:[]
					};
					Util.setNotifyMembersType($scope.newComment, $scope.job)
					$scope.uploader.queue = [];
					fetchComments();
				});
			})
		} else {
			DocumentsService.createComment(newComment).$promise.then(function(res) {
				$scope.uploader.queue = [];
				$scope.newComment = {
					notifyMembers:[]
				};
				Util.setNotifyMembersType($scope.newComment, $scope.job)
				fetchComments();
			});
		}
	}
	$scope.deleteComment = function(comment) {
		DocumentsService.removeComment(comment).$promise.then(function(res){
			$scope.attachments = [];
			fetchComments();
		})
	}
	$scope.saveCommentChange = function(comment){
		DocumentsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.cancelCommentEdit = function(){
		fetchComments();
	}
	$scope.deleteAttachment = function(comment, attachment){
		var index = comment.attachments.indexOf(attachment)
		if (index > -1){
			comment.attachments.splice(index, 1);
		};
		var newAttachmentList = comment.attachments;
		DocumentsService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.filterItems = function(_items, _system) {
		var items = null
		items = _.filter(_items, function(item){
			return $scope.calcCount(item, _system) > 0
		})
		if (!items) {
			items = []
		}
		return items
	}
	$scope.calcCount = function(item, _system, level) {
		var source = null
		source = _.find(item.systemSources, function(ss){
			return ss.systemId == _system.id
		})
		var count = 0
		if (!source) {
			// console.log("source not found for ", item)
		} else if (level) {
			count = source[level]
		} else {
			_.each(["stack", "supply", "exhaust", "other"], function(level){
				count = count + source[level]
			})
		}
		return count
	}
	function multipleSystemsSupported(design) {
		return design.include.stack && ! design.include.exhaust
	}
	$scope.hasMultipleSystems = function(design) {
		try {
			return multipleSystemsSupported(design) && design.fc5layout.systems.length > 1
		}catch(e) {
			return false
		}
	}
});
